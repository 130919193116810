const headerId = 'js-main-header';
const headerSearchId = 'js-main-header-search';

const activeClass = 'is-scrolled';
const searchActiveClass = '-search-active';

const header = document.getElementById(headerId);
const headerSearchWrapper = document.querySelector('.header-search');
const headerSearchButton = document.getElementById(headerSearchId);

window.addEventListener('scroll', function () {
    scrollpos = window.scrollY;

    if (scrollpos >= 100) {
        add_class_on_scroll(true);
    } else {
        add_class_on_scroll(false);
    }
});

if (headerSearchButton) {
    headerSearchButton.addEventListener('click', event => toggleSearch(event));
}

if (headerSearchWrapper) {
    window.addEventListener('click', function (e) {
        if (!header.contains(e.target))
            header.classList.remove(searchActiveClass);
    });
}

function add_class_on_scroll(show) {
    if (header) {
        if (show) {
            header.classList.add(activeClass);
        } else {
            header.classList.remove(activeClass);
        }
    }
}

const toggleSearch = event => {
    header.classList.toggle(searchActiveClass);
};

//MOBILE NAV
const mobileTriggerId = 'js-dropdown-main-trigger';
const mobileNavId = 'js-dropdown-main-nav';
const mobileTrigger = document.getElementById(mobileTriggerId);
const mobileNav = document.getElementById(mobileNavId);
const mobileActiveClass = 'is-active';
const menuActiveClass = 'is-expanded';

mobileTrigger.addEventListener('click', event => {
    if (mobileTrigger.classList.contains(mobileActiveClass)) {
        mobileTrigger.classList.remove(mobileActiveClass);
        mobileNav.classList.remove(mobileActiveClass);
        header.classList.remove(menuActiveClass);
    } else {
        mobileTrigger.classList.add(mobileActiveClass);
        mobileNav.classList.add(mobileActiveClass);
        header.classList.add(menuActiveClass);
    }
});
